.App 
{
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    overflow: hidden;
}

.swiper-pagination-bullet {
    background: var(--color-mapped-text-content-primary) !important; 
}
.swiper-pagination-bullet-active {
    background: var(--color-mapped-text-action-1-hover) !important;
}

