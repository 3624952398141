/* styles for ClientsLogos  */
.clients-logo.clients-logo-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    padding: 4rem 0px var(--vertical-space) 0px
}

.clients-logo .title-6{
    width: 100%;
    text-align: center;
    color: var(--color-mapped-text-content-secondary);
}

@media screen and (max-width: 768px) {
    .clients-logo.clients-logo-container{
        padding: 2rem 0px 3rem 0px;
        gap: 1.5rem;
    }
}




/* styles for ClientsLogosCarousel  */
.clients-logos-carousel.clients-logos-carousel-container {
    background-color: black;
    overflow: hidden;
    position: relative;
}

.clients-logos-carousel .fade-overlay {
    position: absolute;
    top: 0;
    width: 100px;
    height: 100%;
    z-index: 1;
}

.clients-logos-carousel .fade-left {
    left: 0;
    background: linear-gradient(to right, black, transparent);
}

.clients-logos-carousel .fade-right {
    right: 0;
    background: linear-gradient(to left, black, transparent);
}

.clients-logos-carousel .logo-rail {
    display: flex;
    gap: 4.37rem;
    padding-left: 100px;
    padding-right: 100px;
}

.clients-logos-carousel .logo {
    height: 50px;
    width: auto;
    object-fit: contain;
    cursor:grab;
}

@media screen and (max-width: 768px) {
    .clients-logos-carousel .logo-rail {
        gap: 1.5rem;
    }
    .clients-logos-carousel .logo {
        height: 30px;
    }
}