.challenges.challenges-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: var(--vertical-space) 1.5rem;
  background-color: var(--color-neutrals-black);
  height: auto;
  position: relative;
}

.challenges-overlay {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  height: 352px;
  background: linear-gradient(
    180deg,
    #000000b0 0%,
    rgba(0, 0, 0, 0.678) 5%,
    rgba(0, 0, 0, 0) 38.67%,
    rgba(0, 0, 0, 0) 59.27%,
    rgba(0, 0, 0, 0.678) 95%,
    #000000b0 100%
  );
  pointer-events: none;
  z-index: 2;
}

.challenges .title-2 {
  color: var(--color-mapped-text-content-primary);
  margin-bottom: 1.5rem;
}

.challenges .title-5 {
  color: var(--color-mapped-text-content-secondary);
  margin-bottom: 1rem;
}

.challenges-scroller.challenges-scroller-container {
  color: #fff;
  font-size: 18px;
  background: black;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 100%;
  position: relative;
  height: 100vh;
}

.challenges-scroller.challenges-scroller-container::-webkit-scrollbar {
  display: none;
}

.challenges-scroller .challenge-item {
  display: flex;
  padding: 1rem 1.2rem;
  justify-content: center;
  align-items: center;
  gap: 0;
  border-radius: 6250000rem;
  transition: all 0.3s ease;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  text-align: center;
  top: 50%;
}

.challenges-scroller .far-card {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.1);
}

.challenges-scroller .near-card {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.1);
}

.challenges-scroller .center-card {
  border: 1px solid #cf3c20;
  background: #f15a2d;
  box-shadow: 0px 0px 26px 2px rgba(255, 255, 255, 0.1);
  color: #fff;
}