.error404 
{
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-4);
    overflow: hidden;
}
.error404 .bgimg 
{
    position: absolute;
    width: 100%;
    object-fit: cover;
    z-index: -1;
}
.error404 .header 
{
    justify-content: center;
} 
.error404 .logo 
{
    width: 50%;
    display: flex;
    justify-content: center;
}
.error404 nav, .error404 .nav-menu 
{
    display: none;
}
.error404 .content 
{
    width: 52%;
    max-width: 600px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:var(--space-3);
    height: 100vh;
}
.error404 .image-box 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    gap: var(--space-1);
}
.error404 .image-box img 
{
    height: 100%;
    width:30%;
}
.error404 .error-0 
{
    background-color: white;
    border-radius: 50%;
    aspect-ratio: 1/1;
    height: 100%;
    width: 100% !important;
}
.error404 .footer-container 
{   
    width: vw;
    box-sizing: border-box;
    padding: var(--spacing-32) var(--space-4) var(--space-4) var(--space-4);
    background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
}
.error404 .light-me {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: radial-gradient(circle at 50% 50%, transparent 15%, rgba(0, 0, 0, 0.95) 15%);
    pointer-events: none;
}
@media (max-width:750px)
{
    .error404 
    {
        height: 100%;
    }
    .error404 header 
    {
        margin: 0;
        padding: var(--space-3) var(--space-2) var(--space-2) var(--space-2);
    }
    .error404 .header 
    {
        display: flex;
        position: static;
        width: 100%;
    }
    .error404 .content 
    {
        width: 100%;
    }
    .error404 .image-box {
        gap: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 100px;
    }
    .error404 .error-0 
    {
        width: auto !important;
    }
    .error404 .light-me 
    {
        display: none;
    }
}