/* ClientsProblems styles */
.clients-problems.clients-problems-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: var(--vertical-space) 0;
    background-color: var(--color-neutrals-black);
}

.clients-problems-container .title-2 {
    color: var(--color-mapped-text-content-primary);
    margin-bottom: 0;
}

.clients-problems-container .clients-problems-cards-container {
    width: 100%;
    padding: 4rem 0px 2rem;
}

/* .swiper-button-next, .swiper-button-prev{
    opacity: 1 !important;
  background: green !important;
  height: 100% !important;
  border-radius: 0px !important;
  width: 100% !important;
  position: absolute !important;
  top: 0px !important;
  max-width: 227px !important;
} */

.swiper-container {
    width: 100%;
    position: relative;
}

.custom-swiper-button-prev,
.custom-swiper-button-next {
    position: absolute;
    top: 0px;
    height: 100%;
    z-index: 10;
    background: transparent;
    border: none;
    cursor: none;
    padding: 10px;
    max-width: 100%;
    width: 30%;
}

.custom-swiper-button-prev {
    left: 0px;
}

.custom-swiper-button-next {
    right: 0px;
}

.clients-problems .swiper-pagination {
    display: none;
}

@media screen and (max-width: 768px) {
    .clients-problems.clients-problems-container {
        padding: 3rem 0;
    }

    .clients-problems-container .title-2 {
        margin-bottom: 2rem;
        font-family: var(--font-family-2) !important;
        max-width: var(--max-width);
    }

    .clients-problems-container .clients-problems-cards-container {
        padding: 0px 1.5rem 3.37rem 1.5rem;
    }
    .clients-problems .swiper-pagination {
        display: block;
        bottom: 15px;
    }
}

/* ClientsProblemsCard styles */
.clients-problems .swiper-slide {
    background-position: center;
    background-size: cover;
    max-width: 52rem;
    width: auto;
    height: auto;
    aspect-ratio: 16/9;
}

.clients-problems-card.clients-problems-card-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    box-shadow: 0px 0px 36px 12px rgba(255, 255, 255, 0.1);
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
}

.clients-problems-card .image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.clients-problems-card .clients-problems-card-box {
    width: calc(100% - 1.5rem);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    margin: 0px 0.5rem 0.5rem 0.5rem;
    backdrop-filter: blur(18.370847702026367px);
    background: var(--color-primitives-glass-fill-dark);
    border-radius: 0.5rem;
    border: 1.148px solid var(--color-primitives-glass-stroke);
}

.clients-problems-card .clients-problems-card-inner-container {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
}

.clients-problems-card .text.title-6 {
    color: var(--color-mapped-text-content-primary);
}

.clients-problems-card .sub-text.title-7 {
    color: var(--color-mapped-text-content-tertiary);
}

.clients-problems-card .logo {
    width: 32px;
    height: 32px;
    object-fit: contain;
    cursor: pointer;
}

@media screen and (min-width: 1512px) {
    .clients-problems .swiper-slide {
        max-width: 52rem;
        width: 52rem;
    }
}

@media screen and (max-width: 768px) {
    .clients-problems .swiper-slide {
        width: auto;
        height: auto;
        aspect-ratio: 1/1;
    }

    .clients-problems-card.clients-problems-card-container {
        width: auto;
        aspect-ratio: auto;
    }

    .clients-problems-card .image {
    }

    .clients-problems-card .clients-problems-card-container {
        padding: 1rem;
        border: 1.148px solid var(--color-primitives-glass-stroke);
    }

    .clients-problems-card .clients-problems-card-inner-container {
        gap: 0.5rem;
    }

    .clients-problems-card .clients-problems-card-box {
        padding: 1rem;
    }

    .clients-problems-card .text.title-6 {
    }

    .clients-problems-card .sub-text.title-7 {
    }

    .clients-problems-card .logo {
        width: 24px;
        height: 34px;
    }
}

.clients-problems .swiper-button-next,
.clients-problems .swiper-button-prev {
    display: flex;
    justify-content: center;
    width: 10%;
    max-width: 102px;
    height: auto;
    aspect-ratio: 1;
    align-items: center;
    border-radius: 50%;
    background: var(--color-primitives-glass-fill-dark);
    border: 1px solid var(--color-primitives-glass-stroke);
    backdrop-filter: blur(16px);
    color: var(--color-mapped-text-content-primary) !important;
    opacity: 0;
}

.clients-problems.clients-problems-container:hover {
    .swiper-button-next {
        opacity: 1;
    }
    .swiper-button-prev {
        opacity: 1;
    }
}

.clients-problems .swiper-button-prev {
    left: 10% !important;
}

.clients-problems .swiper-button-next {
    right: 10% !important;
}
