.hero-banner {
    width: 100%;
    height: auto;
    padding: 11.75rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
}

.hero-banner .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    position: center;
    object-fit: cover;
    z-index: -1;
}

.hero-banner .info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 58.37rem;
    position: relative;
}

.hero-banner .test-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.hero-banner .title-1 {
    color: var(--color-mapped-text-content-primary);
    text-align: center;
    margin-bottom: 1rem;
}

.hero-banner .title-5 {
    color: var(--color-mapped-text-action-1);
    text-align: center;
    margin-bottom: 2rem;
}

.hero-banner .description {
    color: var(--color-mapped-text-content-secondary);
    text-align: center;
    margin-bottom: 2rem;
}

.hero-banner .message-1 {
    color: var(--color-mapped-text-content-secondary);
    position: absolute;
    top: 20%;
    left: -20%;
}

.hero-banner .img-1 {
    position: absolute;
    top: -6%;
    left: -12%;
}

.hero-banner .message-2 {
    color: var(--color-mapped-text-content-secondary);
    position: absolute;
    top: -26%;
    left: 52%;
}

.hero-banner .img-2 {
    position: absolute;
    top: -30%;
    left: 33%;
}

.hero-banner .message-3 {
    color: var(--color-mapped-text-content-tertiary);
    position: absolute;
    right: -19%;
    top: -17%;
}

.hero-banner .img-3 {
    position: absolute;
    top: -27%;
    right: 4%;
}

@media screen and (max-width: 1330px) {
    .hero-banner .message-1 {
        left: -14%;
    }

    .hero-banner .message-3 {
        right: -14%;
    }

    .hero-banner .img-1 {
        top: -10%;
        left: -14%;
        transform: rotate(-10deg);
    }

    .hero-banner .img-3 {
        position: absolute;
        top: -3%;
        right: -9%;
        transform: rotateX(180deg) rotate(60deg);
    }

    .hero-banner .img-2 {
        position: absolute;
        top: -27%;
        left: 31%;
    }
}

@media screen and (max-width: 1220px) {
    .hero-banner .info-container{
        max-width: 80%;
    }

}

@media screen and (max-width: 950px) {
    .hero-banner .img-2 {
        left: 26%;
      }
      .hero-banner .img-1 {
        top: -13%;
        left: -10%;
        transform: rotate(-20deg);
      }
}

@media screen and (max-width: 768px) {
    .hero-banner {
        padding:6rem 1.5rem 4.25rem 1.5rem;
    }

    .hero-banner .info-container {
        max-width: 100%;
    }

    .hero-banner .test-container {
        max-width: 21.37rem;
        position: relative;
    }

    .hero-banner .title-1 {
        margin-bottom: 6rem;
        max-width: 95%;
        font-weight: 500 !important;
    }

    .hero-banner .title-5 {
        margin-bottom: 1rem;
        line-height: 24px;
    }

    .hero-banner .message-1 {
        position: absolute;
        top: 49%;
        left: -3%;
    }

    .hero-banner .img-1 {
        width: 60px;
        top: 57%;
        left: 20%;
        transform: rotateX(180deg) rotate(20deg);
    }

    .hero-banner .message-2 {
        position: absolute;
        top: -30%;
        left: 52%;
    }

    .hero-banner .img-2 {
        width: 75px;
        top: -24%;
        left: 25%;
        transform: rotate(-10deg);
    }

    .hero-banner .message-3 {
        position: absolute;
        right: -3%;
        top: 63%;
    }

    .hero-banner .img-3 {
        width: 60px;
        top: 33%;
        right: -12%;
        transform: rotate(90deg);
    }
}