.FAQ 
{
    width: 100%;
    padding: var(--vertical-space) 2rem;
    position: relative;
}
.FAQ .bg-img {
    position: absolute;
    width: 100%;
    height: 900px;
    left: 0;
    top: 0;
    z-index: -2;
}
.FAQ-container {
    width: 100%;
    margin:0 auto;
    max-width:var(  --max-width);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}



.FAQ-container .bottom-img {
    position: absolute;
    width: 100%;
    height: auto;
    object-fit: cover;
    left: -28%;
    bottom: -160%;
    z-index: -1;
    transform: scale(1.5);
}

.FAQ-container .title {
    width: 50%;
    padding-top: var(--space-2);
    display: flex;
    flex-direction: column;
    gap: var(--space-1);
    max-width: calc(var(--max-width)/2);
}

.FAQ-container .title-6 {
    color: var(--color-mapped-text-action-1);
}

.FAQ-container .qa {
    width: 50%;
    max-width: calc(var(--max-width)/2);
}

.FAQ-container .faq-item {
    border-bottom: 1.5px solid var(--color-primitives-glass-stroke);
}

.FAQ-container .faq-summary {
    padding: var(--space-2) 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.FAQ-container .faq-summary img {
    transition: transform 0.3s ease-in-out;
}

.FAQ-container .faq-summary.open img {
    transform: rotate(45deg);
}

.FAQ-container .faq-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out, margin 0.3s ease-out;
    opacity: 0;
}

.FAQ-container .faq-content .p-2 {
    margin-top: 0;
    transition: margin 0.3s ease-out;
    margin-bottom: 20px;
}

.FAQ-container .faq-summary.open + .faq-content {
    opacity: 1;
}


.FAQ-container .faq-summary.open .title-7 {
    color: var(--color-mapped-text-action-1);
}

@media (max-width:768px) {
    .FAQ 
    {
        padding: var(--vertical-space) 1.5rem;
    }
    .FAQ-container {
        flex-direction: column;
        gap: 32px;
    }

    .FAQ-container .title-6,
    .FAQ-container .qa {
        width: 100%;
    }

    .FAQ-container .p-2 {
        font-size: 12px !important;
    }
    .FAQ-container .bottom-img 
    {
        left: -10%;
        bottom: -15%;
        transform: scale(.6);
    }

    .FAQ-container .title-7{
        line-height: 24px!important;
    }
}