/* Services Styles */
.services.services-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
    align-items: center;
    padding: var(--vertical-space) 2rem;
    background-image: url('./assets/background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.services .title-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 3.13rem;
    margin-bottom: 4rem;
    padding: 0;
    max-width: var(--max-width);
}

.services .title-container .title-2 {
    color: var(--color-mapped-text-content-primary);
    text-align: left;
}

.services .title-container .title-2 span {
    color: var(--color-mapped-text-action-1);
}

.services .title-container .p-2 {
    width: 50%;
    color: var(--color-mapped-text-content-tertiary);
}

.services .content{
    width: 100%;
}
.services .swiper-button-prev, .swiper .swiper-button-next 
{
    display: none;
}
@media screen and (max-width: 1000px) {
    .services.services-container {
        padding: var(--vertical-space) 0px;
    }
    .services .title-container {
        padding: 0px 2rem;
    }
    .services-cards .card 
    {
        padding: 0px 0px 0rem 0px;
    }
    .services-cards .card .image 
    {
        aspect-ratio: 1/1;
    }
    .services-cards .card .active-bar  
    {
        margin-bottom: 0;
    }

    .services-cards .card .info {
        padding: 32px 24px;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .services.services-container {
        padding: 3rem 0px;
        background-image: url('./assets/backgroundMob.svg');
    }

    .services .title-container {
        padding: 0px 1.5rem;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        margin-bottom: 2rem;
    }

    .services .title-container .title-2 {
        width: 100%;
        margin-bottom: 0;
        font-family: var(--font-family-2) !important;
    }

    .services .title-container .p-2 {
        width: 100%;
    }
}




/* ServicesTabs styles */
.services-tabs.services-tabs-container {
    width: 100%;
    max-width: var(--max-width);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 0.5rem;
    background-color: var(--color-primitives-glass-fill);
    border: 1px solid var(--color-primitives-glass-stroke);
    backdrop-filter: blur(16px);
    overflow: hidden;
    margin: 0;
}

.services-tabs .banner {
    width: 100%;
    height: auto;
    max-height: 350px;
    object-fit: cover;
}

.services-tabs .cards-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
}

.services-tabs .card {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 2rem 0px;
    border-right: 1px solid var(--color-primitives-glass-stroke);
    backdrop-filter: blur(16px);
    cursor: pointer;
}

.services-tabs .active-bar {
    width: 100%;
    height: 0.15rem;
    margin-bottom: 2rem;
    background-color: var(--color-mapped-text-action-1-hover);
    opacity: 0;
}

.services-tabs .card .title-5 {
    padding: 0px 1.5rem;
    color: var(--color-mapped-text-content-primary);
    margin-bottom: 1rem;
}

.services-tabs .card .body-1 {
    padding: 0px 1.5rem;
    color: var(--color-mapped-text-content-tertiary);
    margin-bottom: 0.5rem;
    display: flex;
    gap: 0.5rem;
}

.services-tabs .bullet{
    width: 1rem;
    height: 1rem;
}

.services-tabs .card.active {
    background-color: var(--color-primitives-glass-stroke);
}

.services-tabs .card:hover {
    background-color: var(--color-primitives-glass-stroke);
}

.services-tabs .card:last-child {
    border-right: none;
}

  
.services span {
    display: inline-block;
    transition: transform 0.3s ease-out!important;
}

.tha-do-text {
    color: #fff !important;
    transition: transform 0.6s cubic-bezier(0.25, 0.1, 0.25, 1)!important;
    will-change: transform!important;
  }

/* ServicesCards styles */
.services-cards.services-cards-container{
    width: 100%;
    padding: 0px 1.5rem;
    display: flex;
    align-items: stretch;
}

.services-cards .card{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 0.5rem;
    border: 1px solid var(--color-primitives-glass-stroke);
    background: var(--color-primitives-glass-stroke);
    overflow: hidden;
    padding: 0px 0px 0  0px;
    backdrop-filter: blur(16px);
    margin-bottom: 3rem;
}

.services-cards .card .image{
    width: 100%;
    height: auto;
    object-fit: cover;
}

.services-cards .card .active-bar{
    width: 100%;
    height: 0.15rem;
    background-color: var(--color-mapped-text-action-1-hover);
}

.services-cards .card .title-5{
    padding: 0px 1.5rem;
    color: var(--color-mapped-text-content-primary);
    margin-bottom: 1rem;
    font-size: 24px !important;
}

.services-cards .card .body-1 {
    padding: 0px 1.5rem;
    color: var(--color-mapped-text-content-tertiary);
    margin-bottom: 0.5rem;
    display: flex;
    gap: 0.5rem;
    font-size: 16px !important;
}

.services-cards .card .bullet{
    width: 1rem;
    height: 1rem;
}

.swiper {
    width: 100%;
    height: auto;
  }