.cursor-container {
    position: absolute;
    height: 100vh;
}

.cursor-circle {
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 50%;
    mix-blend-mode:screen;
    pointer-events: none;
    background-color: var(--color-mapped-text-action-1-hover);
    transition: width 0.3s ease-out, height 0.3s ease-out, filter 0.3s ease-out;
}

.cursor-circle-video{
    border: 1px solid var(--color-primitives-glass-stroke);
    background: var( --color-primitives-glass-fill-dark);
    backdrop-filter: blur(0px);
    mix-blend-mode: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    cursor: pointer;
    overflow: hidden;
}

.cursor-circle-slider{
    mix-blend-mode: unset;
    z-index: 2;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: var(--color-primitives-glass-fill-dark);
    border: 1px solid var(--color-primitives-glass-stroke);
    backdrop-filter: blur(16px);
    color: var(--color-mapped-text-content-primary) !important;

}

@media (max-width:750px)
{
    .cursor-circle-video{
        
    }
}