.construction {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.construction .bgimg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
}

.construction .contents {
    width: 100%;
    height: 100vh;
    padding: var(--space-5); /* Ensure this is applied correctly */
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    border-bottom: 1px solid var(--glass-Color, rgba(241, 90, 45, 0.40));
    box-sizing: border-box; /* Include box-sizing */
    padding-bottom: 300px;
}

.construction .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.construction h1 {
    margin-top: var(--space-1);
    margin-bottom: var(--spacing-32);
}

.construction button {
    margin: 0;
}
.construction .image-box 
{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.construction .contents .logo {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 350px;
}

.construction .contents .logo img {
    display: block;
    max-width: 100%;
    position: absolute;
    cursor: grab;
}

.construction .contents .main-logo {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    aspect-ratio: 1/1;
    position: relative; /* Main logo stays relative */
    transition: 200ms ease-in;
}

.construction .contents .logo1 {
    top:240px;
    left: -350px;
    width: 286px;
    height: 208px;
    z-index: 2;
    animation: bounce 1500ms ease-in-out;
}

.construction .contents .logo2 {
    top:426px;
    left: 86px;
    width: 180px;
    height: 50px;
    z-index: 4;
    animation: bounce 1750ms ease-in-out;
}

.construction .contents .logo3 {
    top: 281px;
    left: 410px;
    width: 50%;
    height: 50%;
    z-index: 3;
    animation: bounce 2000ms ease-in-out;
}
.construction .contents .active-shadow 
{
    box-shadow: 0px 0px 50px 0px rgba(253, 207, 171, 0.50);
}
.construction .contents .logo img.active {
    -webkit-filter: invert(1); /* For Chrome, Safari, and Opera */
    -moz-filter: invert(1); /* For Firefox */
    -ms-filter: invert(1); /* For Internet Explorer */
    -o-filter: invert(1); /* For older Opera versions */
    filter: invert(1); /* Standard */
}
.construction .logo img.celebrate {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: none;
}

/* Define the keyframes for the drop animation */
/* Define the keyframes for the bounce animation */
@keyframes bounce {
    0% {
        transform: translateY(-1000px);
    }
    60% {
        transform: translateY(0);
    }
    70% {
        transform: translateY(-30px);
    }
    80% {
        transform: translateY(0);
    }
    90% {
        transform: translateY(-15px);
    }
    100% {
        transform: translateY(0);
    }
}



.construction .footer-container 
{
    padding: var(--spacing-32) var(--space-4) var(--space-4) var(--space-4);
}

@media (max-width: 750px) {
    .construction .header 
    {
        margin-left:24px;
    }
    .construction {
        padding: 0;
    }

    .construction .contents {
        padding: var(--space-2) var(--space-2) var(--space-5) var(--space-2); /* Removed conflicting padding: 0 */
        gap: var(--space-3);
        height: auto;
        flex-direction: column;
        padding-bottom: 200px;
    }

    .construction .info {
        align-items: center;
        text-align: center;
    }

    .construction h1 {
        margin-top: var(--spacing-16);
        margin-bottom: var(--space-2);
    }
    .construction .contents .logo 
    {
        width: 250px;
        height: 250px;
    }
    .construction .contents .logo1 
    {
        height: 147px;
        width: 208px;
        top: 250px;
        left: -88px;
    }
    .construction .contents .logo2 
    {
        width: 129px;
        height: 36px;
        top: 390px;
        left: 50px;
    }
    .construction .contents .logo3 
    {
        top: 300px;
        left: 233px;
    }
    .construction .image-box 
    {
        width: 100%;
    }
}

@media (min-width: 751px) and (max-width: 1439px) {
    .construction .contents .logo1 {
        left: -200px;
    }
    .construction .contents .logo3 {
        left: 300px;
    }
}