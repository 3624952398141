/* CareersHero */

.careers-hero.careers-hero-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: var(--vertical-space) 2rem;
    background: url('./assets/background.svg');
    background-size: cover;
}

.careers-hero .title-2{
    max-width: var(--max-width);
    color: var(--color-mapped-text-content-primary);
    margin-bottom: 1rem;
    text-align: center;
}

.careers-hero .title-5{
    max-width: var(--max-width);
    color: var(--color-mapped-text-content-secondary);
    margin-bottom: 3rem;
    text-align: center;
}

.careers-hero .video-section 
{
    max-width: var(--max-width);
    width:38rem;
    height: 21rem;
    background: var(--color-primitives-glass-fill-dark);
    backdrop-filter: blur(16px);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    cursor:  none !important;
}
.careers-hero video 
{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.careers-hero .playBtn 
{
    position: absolute;
    cursor: pointer;
    z-index: 2;
}

.careers-hero .playBtn.mobile{
    display: none;
}

@media screen and (max-width: 768px) {
    .careers-hero.careers-hero-container{
        padding: var(--vertical-space) 1.5rem;
        background: url('./assets/backgroundMob.svg');;
    }
    
    .careers-hero .title-2{
        color: var(--color-mapped-text-content-primary);
        margin-bottom: 1rem;
    }
    
    .careers-hero .title-5{
        color: var(--color-mapped-text-content-secondary);
        margin-bottom: 3rem;
    }
    
    .careers-hero .video-section 
    {
        width:100%;
        aspect-ratio: 16/9;
        height: auto;
    }
    .careers-hero video 
    {

    }
    .careers-hero .playBtn 
    {

    }
    .careers-hero .playBtn.mobile{
        display: flex;
    }
}