/* WithUs styles */
.with-us.with-us-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: var(--vertical-space) 2rem;
    background: url('./assets/background.svg');
    background-size: cover;
    background-position: center;
}

.with-us.with-us-container .title-2 {
    color: var(--color-mapped-text-content-primary);
    margin-bottom: 1.5rem;
}

.with-us.with-us-container .title-5 {
    color: var(--color-mapped-text-content-secondary);
    margin-bottom: 4rem;
}

@media screen and (max-width: 768px) {
    .with-us.with-us-container {
        padding: 3rem 1.5rem;
        background: url('./assets/background.svg') , url('./assets/background.svg');
        background-repeat: no-repeat , no-repeat;
        background-position: 50% 30% , 50% 75%;
        background-size: 175% auto , 175% auto;
    }

    .with-us.with-us-container .title-2 {
        margin-bottom: 1rem;
        font-family: var(--font-family-2) !important;
    }

    .with-us.with-us-container .title-5 {
        margin-bottom: 2rem;
        text-align: center;
    }
}


/* WithUsCards styles */
.with-us-cards.with-us-cards-container {
    width: 100%;
    max-width: var(--max-width);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 1rem;
}

@media screen and (max-width: 768px) {
    .with-us-cards.with-us-cards-container {
        flex-direction: column;
        justify-self: flex-start;
        align-items: center;
        gap: 0.5rem;
    }
}


/* WithUsCard styles */
.with-us-card.with-us-card-container {
    max-width: 25.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 0.5rem;
    border: 1px solid var(--color-primitives-glass-stroke);
    background: var(--color-primitives-glass-fill);
    padding: 0px;
    backdrop-filter: blur(16px);
    flex: 1;
}



.with-us-card .image {
    width: auto;
    height: 12rem;
    
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    /* margin-bottom: 1.76rem; */
}

.with-us-card .image div {
    height: 100%;
    width: 100%;
}

.with-us-card .title-5.card-title {
    color: var(--color-mapped-text-content-primary);
    margin-bottom: 1rem;
    padding: 1.5rem 1.5rem 0px 1.5rem;
    border-top: 1px solid var(--color-primitives-glass-stroke);
    width: 100%;
}

.with-us-card .p-4.card-description {
    color: var(--color-mapped-text-content-primary);
    flex-grow: 1;
    padding: 0px 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 16px!important;
}

@media screen and (max-width: 768px) {
    .with-us-card.with-us-card-container {
        max-width: 100%
    }

    .with-us-card .card-image {

    }

    .with-us-card .title-5 {
    }

    .with-us-card .p-4 {
    }
}