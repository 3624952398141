.title-1 {
  font-family: var(--font-family-1);
  font-size: 64px !important;
  font-weight: 500 !important;
  line-height: 52px;
  letter-spacing: -1.28px;
  text-align: left;
}

.title-1.bold {
  font-weight: 600 !important;
}

.title-2 {
  font-family: var(--font-family-1);
  font-size: 48px !important;
  font-weight: 500 !important;
  line-height: 48px;
  letter-spacing: -0.48px;
  text-align: left;
}

.title-2.bold {
  font-weight: 600 !important;
}

.title-3 {
  font-family: var(--font-family-1);
  font-size: 40px !important;
  font-weight: 500 !important;
  line-height: 40px;
  letter-spacing: -0.4px;
  text-align: left;
}

.title-3.bold {
  font-weight: 600 !important;
}

.title-4 {
  font-family: var(--font-family-2);
  font-size: 32px !important;
  font-weight: 400 !important;
  line-height: 32px;
  letter-spacing: -0.32px;
  text-align: left;
}

.title-4.bold {
  font-weight: 700 !important;
}

.title-5 {
  font-family: var(--font-family-2);
  font-size: 28px !important;
  font-weight: 400 !important;
  line-height: 28px;
  letter-spacing: -0.28px;
  text-align: left;
}

.title-5.bold {
  font-weight: 700 !important;
}

.title-6 {
  font-family: var(--font-family-2);
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 16px;
  letter-spacing: -0.24px;
  text-align: left;
}

.title-6.bold {
  font-weight: 700 !important;
}

.title-7 {
  font-family: var(--font-family-2);
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 24px;
  letter-spacing: -0.18px;
  text-align: left;
}

.title-7.bold {
  font-weight: 700 !important;
}

.body-1 {
  font-family: var(--font-family-2);
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 16px;
  letter-spacing: -0.16px;
  text-align: left;
}

.body-1.bold {
  font-weight: 700 !important;
}

.body-2 {
  font-family: var(--font-family-2);
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 14px;
  letter-spacing: -0.14px;
  text-align: left;
}

.body-2.bold {
  font-weight: 700 !important;
}

.body-3 {
  font-family: var(--font-family-2);
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 12px;
  letter-spacing: -0.12px;
  text-align: left;
}

.body-3.bold {
  font-weight: 700 !important;
}

.label-1 {
  font-family: var(--font-family-2);
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-align: left;
}

.label-2 {
  font-family: var(--font-family-2);
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 10px;
  letter-spacing: 0.05em;
  text-align: left;
}

.label-3 {
  font-family: var(--font-family-2);
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 9px;
  letter-spacing: 0.05em;
  text-align: left;
}

.default {
  font-family: var(--font-family-3);
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 19.6px;
  letter-spacing: 0.01em;
  text-align: left;
}

.default-2 {
  font-family: var(--font-family-3);
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16.8px;
  letter-spacing: 0.01em;
  text-align: left;
}

.p-1 {
  font-family: var(--font-family-2);
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 24px;
  text-align: left;
}

.p-2 {
  font-family: var(--font-family-2);
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 23px;
  text-align: left;
}

.p-3 {
  font-family: var(--font-family-2);
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px;
  text-align: left;
}

.p-4 {
  font-family: var(--font-family-2);
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 20px;
  text-align: left;
}


@media screen and (max-width: 768px) {
  .title-1 {
    font-size: 40px !important;
    font-weight: 400 !important;
    line-height: 40px;
    letter-spacing: -0.40px;
  }
  
  .title-2 {
    font-size: 32px !important;
    font-weight: 400 !important;
    line-height: 32px;
    letter-spacing: -0.32px;
  }
  
  .title-3 {
    font-size: 32px !important;
    font-weight: 400 !important;
    line-height: 32px;
    letter-spacing: -0.32px;
  }
  
  .title-4 {
    font-size: 32px !important;
    font-weight: 400 !important;
    line-height: 32px;
    letter-spacing: -0.32px;
  }
  
  .title-5 {
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 18px;
    letter-spacing: -0.18px;
  }
  
  .title-6 {
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 18px;
    letter-spacing: -0.18px;
  }
  
  .title-7 {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 14px;
    letter-spacing: -0.14px;
  }

  .body-1 {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 14px;
    letter-spacing: -0.14px;;
  }
  
  .body-2 {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 12px;
    letter-spacing: -0.12px;;
  }
  
  .body-3 {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 12px;
    letter-spacing: -0.12px;
  }

  .label-1 {
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 14px;
    letter-spacing: 0.14px;
  }
  
  .label-2 {
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 12px;
    letter-spacing: 0.12px;
  }
  
  .label-3 {
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 12px;
    letter-spacing: 0.12px;
  }

  .default {
    font-size: 12px !important;
    line-height: 19.6px;
  }
  
  .default-2 {
    font-size: 12px !important;
    line-height: 16.8px;
  }

  .p-1 {
    font-size: 14px !important;
    line-height: 22px;
  }
  
  .p-2 {
    font-size: 14px !important;
    line-height: 22px;
  }
  
  .p-3 {
    font-size: 12px !important;
    line-height: 20px;
  }
  
  .p-4 {
    font-size: 12px !important;
    line-height: 20px;
  }
  
}
