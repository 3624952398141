/* Footer styles */

.footer.footer-container{
    width: 100%;
    padding: 2rem 2rem 4rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
    margin-top: 0px;
    position: relative;
}

.footer .footer-row-1{
    width: 100%;
    max-width: 90rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem
}

.footer .row-1-icon{
    width: 1.5rem;
    height: auto;
    aspect-ratio: 1;
    object-fit: contain;
    cursor: pointer;
}

.footer .footer-row-2{
    width: 100%;
    max-width: 90rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
}

.footer .row-2-text{
    color: var(--color-mapped-text-content-tertiary);
    cursor: pointer;
    text-decoration: none;
}

.footer .bottom-img{
    display: none;
}

@media screen and (max-width: 768px) {
    .footer.footer-container{
        width: 100%;
        padding: var(--space-3) var(--space-2);
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
        gap: 1.5rem;
        margin-top: 0;
        background: linear-gradient(0deg, #0000%, rgba(0, 0, 0, 0.00)100%);
        overflow: hidden;
    }
    
    .footer .footer-row-1{
        display: flex;
        max-width: auto;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 1.5rem;
        margin-bottom: 0
    }
    
    
    .footer .footer-row-2{
        max-width: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0;
    }
    
    .footer .row-2-text{
        margin-bottom: 0.5rem;

    }

    .footer .bottom-img{
        position: absolute;
        width: 100%;
        height: auto;
        object-fit: cover;
        z-index: -1;
        display: block;
        left: -10%;
        top: 30%;
        transform: scale(1);
    }
}