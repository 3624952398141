.house-culture 
{
    width: 100%;
    padding: var(--vertical-space) 2rem;
    display: flex;
    justify-content: center;
    background: url('./assets/background.svg');
    background-size: cover;
}
.house-culture .content 
{
    max-width:var(--max-width);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:16px;
}
.house-culture .title 
{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}
.house-culture .title-2
{
    text-align: left;
}
.house-culture .p-2{
    max-width: 97%;
}
.house-culture .image-box 
{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}
.house-culture .image-box img 
{
    border-radius:8px;
    border: 1px solidrgba(255, 255, 255, 0.10);
}
.house-culture .image-box p 
{
    font-family: var( --font-family-3);
    text-align: center;
}

@media (max-width: 768px) { 
    .house-culture 
    {
        width: 100%;
        padding: var(--vertical-space) 1.5rem;
    }
    .house-culture .content 
    {
        flex-direction: column;
    }
}