/* BookACall styles */
.BAC 
{
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    padding: var(--vertical-space) 2rem;
}
.BAC .bottom-img {
    position: absolute;
    width: 100%;
    height: auto;
    object-fit: cover;
    left: -28%;
    top: 130%;
    z-index: -1;
    transform: scale(1.5);
}
.book-a-call.book-a-call-container{
    width: var(--max-width);
    max-width: var(--max-width);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
}

.book-a-call .title-1{
    color: var(--color-mapped-text-content-primary);
    margin-bottom: 2rem;
    max-width: var(--max-width);
    text-align: center;
}

@media screen and (max-width: 768px) {
    .BAC {
        padding: var(--vertical-space) 1.5rem;
    }
    .book-a-call .title-1{
        max-width: auto;
    }
    .BAC .bottom-img 
    {
        left: -10%;
        top: 30%;
        transform: scale(.6);
        display: none;
    }
}