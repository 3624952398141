/* WhyUs styles */
.why-us.why-us-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: var(--vertical-space) 2rem;
    background: var(--color-neutrals-black);
}

.why-us .title-2 {
    color: var(--color-mapped-text-content-primary);
    margin-bottom: 4rem;
}

.why-us .why-us-cards-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 1rem;
    max-width: var(--max-width);
}

@media screen and (max-width: 768px) {
    .why-us.why-us-container {
        padding: var(--vertical-space) 1.5rem;
    }

    .why-us .title-2 {
        margin-bottom: 2rem;
        font-family: var(--font-family-2);
    }
    
    .why-us .why-us-cards-container {
        flex-direction: column;
        justify-self: flex-start;
        align-items: center;
        gap: 0.5rem;
    }
}


/* WhyUsCard styles */
.why-us-card.why-us-card-container {
    max-width: 25.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 0.5rem;
    border: 1px solid var(--color-primitives-glass-stroke);
    background: var(--color-primitives-glass-fill);
    padding: 1.5rem;
    backdrop-filter: blur(16px);
    flex: 1;
}

.why-us-card .card-image {
    width: 80%;
    height: auto;
    object-fit: contain;
    margin: 0 auto;
    margin-bottom: 1.5rem;
}

.why-us-card .card-number{
    color: var(--color-mapped-surface-action-1);
    margin-bottom: 1rem;
}

.why-us-card .card-title {
    color: var(--color-mapped-text-content-primary);
    margin-bottom: 1rem;
    line-height: normal;
}

.why-us-card .card-description {
    color: var(--color-mapped-text-content-primary);
    flex-grow: 1;
}

@media screen and (max-width: 768px) {
    .why-us-card.why-us-card-container {
        max-width: 100%;
    }
    
    .why-us-card .card-image {

    }
    
    .why-us-card .card-number{
    }
    
    .why-us-card .title-5.card-title {
    }
    
    .why-us-card .p-4.card-description {
    }
    
}