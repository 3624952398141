.testimonial
{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 2rem;
    position: relative;
}
.testimonial .bgimg
{
    position:absolute;
    width: 100%;
    height: 100%;
    object-position: center;
    z-index: -2;
}
.testimonial .title-2 .link-text
{
    text-decoration: none;
    color: var(--color-mapped-text-action-1);
}
.testimonial .main 
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:var(--space-1);
    max-width: var(--max-width);
}
.testimonial .video-section 
{
    width:64%;
    aspect-ratio: 16/9;
    background: var(--color-primitives-glass-fill-dark);
    backdrop-filter: blur(16px);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    cursor:  none !important;
    position: relative;
}
.testimonial video 
{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.testimonial .playBtn 
{
    position: absolute;
    cursor: pointer;
    z-index: 2;
}
.testimonial .desktop 
{
    display: flex;
    opacity: 0;
    width: 112px;
    height: 112px;
    padding: var(--space-3, 48px);
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 8px;
    flex-wrap: wrap;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    border: 1px solid var(--color-primitives-glass-stroke);
    background: var( --color-primitives-glass-fill-dark);
    backdrop-filter: blur(16px);
    transition: 1000ms ease-in-out;
}
.testimonial .video-section:hover
{
    .desktop
    {
        opacity: 1;
    }
}
.testimonial img.playBtn.mobile 
{
    display: none;
    transform: scale(1);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.video-description-box{
    bottom: 0px;
    position: absolute;
    width: calc(100% - 1rem);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    margin: 0px 0.5rem 0.5rem 0.5rem;
    backdrop-filter: blur(16px);
    background: var(--color-primitives-glass-fill-dark);
    border-radius: 1rem;
    border: 1px solid var(--color-primitives-glass-stroke);
    transition: opacity 0.3s ease, transform 0.3s ease;
}
.video-description-box-inner-container{
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
}

.video-description-box .text.title-6{
    font-size: 16px !important;
    font-weight: 400 !important;
    color: var(--color-mapped-text-content-primary);
}
.video-description-box .sub-text.title-7{
    color: var(--color-mapped-text-content-tertiary);
    font-size: 14px !important;
    font-weight: 400px;
}

.testimonial .cards 
{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--space-1);
}
.testimonial .card 
{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    


}
.testimonial .ceo-img 
{
    width: 100%;
    aspect-ratio: 1.45 / 1;
    overflow: hidden;
    position: relative;
    border-radius: 0.5rem;
}
.testimonial .ceo-img img 
{
    position: absolute;
    width: 100%;
    object-fit: cover;
    transform: translateY(-20px);
}
.testimonial .info-area 
{
    border-radius: 0.5rem;
    padding:2rem;
    background: var(--color-primitives-glass-fill, rgba(0, 0, 0, 0.10));
    backdrop-filter: blur(16px);
    border: 1px solid var(--color-primitives-glass-stroke);
    display: flex;
    flex-direction: column;
    
}
.testimonial .ceo-info
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}
.testimonial .ceo-info .info 
{
    max-width: 343px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
}
.testimonial .ceo-info img 
{
    max-width: 40%;
}
.testimonial .body-2.role{
    color: var(--color-mapped-text-content-tertiary);
}
.testimonial .summary 
{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--color-primitives-glass-stroke) ;
}

.testimonial .summary .p-1.title{
    color: var(--color-mapped-text-content-primary);
}

.testimonial .summary .p-2.message{
    color: var(--color-mapped-text-content-secondary);
}

.hide {
    opacity: 0;
    transform: scale(0.5);
    pointer-events: none;
}

@media (max-width:750px)
{
    .testimonial
    {
        padding: var(--space-3) var(--space-2);
        gap: 0px
    }
    .testimonial .title-2.main-title{
        margin-right: auto;
        line-height: 36px;
        font-family: var(--font-family-2);
        text-align: left;
    }
    .testimonial .desktop 
    {
        display: none;
    }
    .testimonial img.playBtn.mobile  
    {
        display: block;
        top: 8px;
        left: 8px;
    }
    .testimonial .bgimg 
    {
        width: auto;
        height: 100%;
    }
    .testimonial .cards  
    {
        grid-template-columns: 1fr;
    }
    .testimonial .video-section 
    {
        width: 100%;
    }
    .video-description-box{
        padding: 1rem;
        border-radius: 0.5rem;
    }

    .video-description-box .sub-text.title-7{
        font-size: 12px !important;
    }
    .testimonial .info-area 
    {
        padding:var(--space-2)
    }
    .testimonial .body-1.name{
        font-size: 16px !important;
    }
    .testimonial .p-1 
    {
        font-size: 18px !important;
    }
}