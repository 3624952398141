.always-hiring-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: var(--vertical-space) 2rem;
    color: #ffffff;
    text-align: center;  
    background: url('./assets/background.svg');
    background-size: cover;
    background-color: #000000;
}

.always-hiring-container .title-2{
    margin-bottom: 1rem;
    text-align: center;
}

.always-hiring-container .title-5{
    margin-bottom: 1rem;
    text-align: center;
}

.always-hiring .description {
    color: var(--color-mapped-text-content-secondary);
    text-align: center;
    margin-bottom: 2rem;
    max-width: 62vw;
}

.always-hiring .email-link {
    color: var(--color-mapped-text-action-1);
    text-decoration: none;
}

.always-hiring .info-box {
    width: 832px;
    height: 104px;
    padding: 24px 24px 32px 32px;
    gap: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-right: none;
    border-bottom: none;
    border-left: none;
    background: rgba(255, 255, 255, 0.1);
    display: flex; 
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
}

.always-hiring .info-text {
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

.always-hiring .info-text:last-child {
    margin-bottom: 0;
}

@media screen and (max-width: 768px) {
    .always-hiring-container {
        padding: var(--vertical-space) 1.5rem;
    }

    .always-hiring .title-1 {
        font-size: 2.5rem;
    }

    .always-hiring .subtitle {
        font-size: 1.2rem;
    }

    .always-hiring .description {
        max-width:  342px;
    }

    .always-hiring .info-box {
        max-width:  342px;
        height: 174px;
        padding: 16px 24px;
        font-size: 14px;
    }

    .always-hiring .info-box .p-2 {
        max-width: 285px;
    }
}