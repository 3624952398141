.home-page{
    position: relative;
    overflow: hidden;
}
.home-page .home-bottom-croc-img{
    position: absolute;
    bottom: 49px;
    z-index: -1;
    width: 100%;
    object-fit: cover;
    left: -32%;
    transform: scale(1.5);
    height: 237px;
    object-position: top;
}

@media screen and (max-width: 768px) {
    .home-page .home-bottom-croc-img{
        bottom: -2px;
        left: -18%;
        transform: scale(1.2)
    }
}