header {
    width: 100%;
    box-sizing: border-box;
    padding: var(--space-3) var(--space-4) var(--space-2) var(--space-4);
    transition: all 0.3s ease-in-out;
}

header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    padding: var(--space-1) var(--space-4);
}

.header {
    width: 100%;
    box-sizing: border-box;
    --space-1: 24px;
    --space-2: 48px;
    --space-3: 64px;
    --space-4: 128px;
    padding: var(--space-1) var(--space-2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--color-primitives-glass-fill);
    position: relative;
    border-radius: 8px;
    border: 1px solid var(--color-primitives-glass-stroke);
    backdrop-filter: blur(16px);
    gap: var(--space-1);
    transition: all 0.3s ease-in-out;
}

.header.sticky {
    border-radius: 0;
    padding: var(--space-1) var(--space-2);
}
.header .logo img 
{
    height: 100%;
}
nav 
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    gap:var(--space-1);
    transition: all 300ms ease-in-out;
}

.header-summary 
{
    letter-spacing: -0.18px; 
}
.header-summary p 
{
    font-family: var(--font-family-2) !important;
    color: var(--color-mapped-text-content-tertiary);
}
.header-summary a 
{
    color: var(--color-mapped-text-action-1);
}
.nav-menu
{
    display: none;
}

@media (max-width:900px) {
    
    header {
        margin: var(--space-2) var(--space-2) 5rem var(--space-2);
        position: relative;
        padding: 0;
    }

    header.sticky {
        margin: var(--space-2) var(--space-2) var(--space-4) var(--space-2);
        padding: 0;
        width: 100%;
    }

    .header {
        padding:0;
        display: grid;
        position: relative;
        margin: 0;
        top: 0;
        left: 0;
        z-index: 100;
        width: calc(100% - var(--space-2));
        box-sizing: border-box;
        gap: 0;
    }

    .header.sticky {
        width: calc(100% - var(--space-2));
        padding: 0;
    }

    .header .logo
    {
        grid-row: 1 / 1;
        grid-column: 1 / 3;
        padding: var(--space-1) 0 var(--space-1) var(--space-1);
    }
    nav 
    {
        display: none;
        transition: 200ms ease-in-out;
    }
    .header .nav-menu 
    {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        grid-row: 1 / 1;
        grid-column: 2 / 3;
        padding-right: var(--space-1);
    }
    .nav-menu svg 
    {
        width: 24px;
        height: 24px;
    }
    .nav-menu svg 
    {
        width: 24px;
        height: 24px;
    }
    .nav-menu path 
    {
        fill:var(--color-mapped-surface-action-1);
    }
    .header .open 
    {
        display: flex;
        grid-row: 2 / 3;
        grid-column: 1 / 3;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 0;
    }
    .header-summary {
        letter-spacing: -0.18px;
        padding: 32px var(--space-1);
    }
    .header .header-summary .title-7
    {
        font-size: 48px !important;
        font-weight: 500 !important;
        line-height: 48px !important; /* 100% */
        letter-spacing: -0.48px !important;
        font-family: var(--font-family-1) !important;
    }
}