/* OpenPositions.css */
.careers-wrapper {
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    background: url('./assets/images/base-bg.png');
}

.careers-container {
    margin: 0 auto;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    position: relative;
    z-index: 2;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
    display: flex;
}

.careers-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.careers-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
}

.title-2 {
    color: var(--color-mapped-text-content-primary);
    text-align: center;
    margin-bottom: 2rem;
}

.job-listing {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(16px);
    border-radius: 8px;
    margin-bottom: 20px;
    overflow: hidden;
    transition: all 0.3s ease;
    max-width: 1044px;
    width: 1044px;
    min-height: 165px;
    padding: 32px;
}

.job-listing.expanded {
    border-radius: var(--Radius-md, 8px);
    border: 1px solid var(--glass-Color, rgba(241, 90, 45, 0.4));
    background: linear-gradient(
            0deg,
            rgba(241, 90, 45, 0) 86.35%,
            rgba(241, 90, 45, 0.2) 103.3%
        ),
        var(--glass-glass-stroke, rgba(255, 255, 255, 0.1));
    backdrop-filter: blur(16px);
}

.job-header {
    padding: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
}

.job-header h3 {
    margin: 0;
    color: #ffffff;
    font-size: 32px !important;
    line-height: 36px !important;
    letter-spacing: -0.01em !important;
}

.job-subheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;
    max-width: 424px;
}

.job-meta {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    color: #cccccc;
    font-size: 14px;
    justify-content: space-between;
    max-width: 726px;
}

.job-meta > span {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    color: #feead4;
    font-family: "Space Grotesk";
}

.location-detail {
    display: block;
    font-size: 0.9em;
    color: #cccccc;
}

.closing-soon {
    background: #8b0f0e;
    border: 1px solid #73080e;
    color: white;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 12px;
    width: 142px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.closing-soon svg {
    flex-shrink: 0;
}

.toggle-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #ffffff;
    background: var(--glass-glass-fill-dark, rgba(0, 0, 0, 0.44));
    border: 1px solid var(--glass-glass-stroke, rgba(255, 255, 255, 0.1));
    border-radius: var(--Radius-s, 4px);
    backdrop-filter: blur(16px);
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
}

.job-details-wrapper {
    display: flex;
    flex-direction: column;
}

.job-details {
    padding: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    color: #cccccc;
    font-family: "Space Grotesk";
    max-height: 300px;
    overflow-y: auto;
}

.job-details h3 {
    margin-top: 0;
    font-size: 18px;
    color: #ffffff;
}

.job-details ul {
    padding-left: 20px;
    font-size: 16px;
}

.requisites,
.responsibilities {
    margin-bottom: var(--Spacing-md, 16px);
}

.last-updated {
    font-size: 14px;
    color: #cccccc;
    margin-bottom: 20px;
    position: absolute;
    right: 60px;
}

.last-updated .label {
    font-weight: 700;
}

.last-updated .date {
    font-weight: 400;
}

.job-button {
    margin-top: 20px;
    align-self: flex-end;
}

.apply-button {
    background-color: #f15a2d;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    display: flex;
    align-items: center;
}

.apply-button::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    background: linear-gradient(to top, rgba(255,255,255,0.2), rgba(255,255,255,0));
    transition: height 0.3s ease;
}

.apply-button:hover {
    box-shadow: 0 0 20px rgba(241, 90, 45, 0.7);
}

.apply-button:hover::before {
    height: 50%;
}

.button-text {
    position: relative;
    z-index: 1;
    transition: text-shadow 0.3s ease;
}

.apply-button:hover .button-text {
    text-shadow: 0 0 10px rgba(255, 255, 255, 1), 0 0 20px rgba(255, 255, 255, 0.8);
}

.button-arrow {
    position: relative;
    width: 20px;
    height: 2px;
    background-color: white;
    margin-left: 10px;
    transition: transform 0.3s ease;
}

.button-arrow::before,
.button-arrow::after {
    content: '';
    position: absolute;
    right: 0;
    height: 2px;
    width: 10px;
    background-color: white;
    transition: transform 0.3s ease;
}

.button-arrow::before {
    top: -3px;
    transform: rotate(45deg);
}

.button-arrow::after {
    bottom: -3px;
    transform: rotate(-45deg);
}

.apply-button:hover .button-arrow {
    transform: rotate(-30deg);
}

.apply-button:hover .button-arrow::before {
    transform: rotate(30deg);
}

.apply-button:hover .button-arrow::after {
    transform: rotate(-60deg);
}

.job-details::-webkit-scrollbar {
    width: 8px;
}

.job-details::-webkit-scrollbar-track {
    background: #fdcfab;
    border-radius: 4px;
}

.job-details::-webkit-scrollbar-thumb {
    background: #fdcfab;
    border-radius: 4px;
}

@media screen and (max-width: 768px) {
    .job-listing {
        width: 100%;
        max-width: 100%;
        padding: 20px;
    }

    .job-header h3 {
        font-size: 24px !important;
        line-height: 28px !important;
    }

    .job-subheader {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .job-meta {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .job-meta > span {
        font-size: 12px;
    }

    .closing-soon {
        width: auto;
        height: 24px;
        font-size: 10px;
        padding: 3px 8px;
    }

    .toggle-icon {
        top: 10px;
        right: 10px;
        width: 36px;
        height: 36px;
    }

    .job-details {
        padding: 15px;
    }

    .job-details h3 {
        font-size: 16px;
    }

    .job-details ul {
        font-size: 14px;
    }

    .last-updated {
        font-size: 12px;
        position: static;
    }

    .apply-button {
        width: 100%;
        justify-content: center;
    }
}
