/* @import url('https://fonts.cdnfonts.com/css/neue-haas-grotesk-display-pro'); */
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../fonts/Neue Haas Grotesk Display Pro/NeueHaasDisplayXXThin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../fonts/Neue Haas Grotesk Display Pro/NeueHaasDisplayXXThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../fonts/Neue Haas Grotesk Display Pro/NeueHaasDisplayXThin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../fonts/Neue Haas Grotesk Display Pro/NeueHaasDisplayXThinItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../fonts/Neue Haas Grotesk Display Pro/NeueHaasDisplayThin.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../fonts/Neue Haas Grotesk Display Pro/NeueHaasDisplayThinItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../fonts/Neue Haas Grotesk Display Pro/NeueHaasDisplayLight.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../fonts/Neue Haas Grotesk Display Pro/NeueHaasDisplayLightItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../fonts/Neue Haas Grotesk Display Pro/NeueHaasDisplayRoman.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../fonts/Neue Haas Grotesk Display Pro/NeueHaasDisplayRomanItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../fonts/Neue Haas Grotesk Display Pro/NeueHaasDisplayMediu.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../fonts/Neue Haas Grotesk Display Pro/NeueHaasDisplayMediumItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../fonts/Neue Haas Grotesk Display Pro/NeueHaasDisplayBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../fonts/Neue Haas Grotesk Display Pro/NeueHaasDisplayBoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../fonts/Neue Haas Grotesk Display Pro/NeueHaasDisplayBlack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../fonts/Neue Haas Grotesk Display Pro/NeueHaasDisplayBlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

:root {

  /* Colors */

  --color-primitives-primary-0: #FEEAD4;
  --color-primitives-primary-1: #FDCFAB;
  --color-primitives-primary-2: #FAAE80;
  --color-primitives-primary-3: #F68D60;
  --color-primitives-primary-4: #F15A2D;
  --color-primitives-primary-5: #CF3C20;
  --color-primitives-primary-6: #AD2316;
  --color-primitives-primary-7: #8B0F0E;
  --color-primitives-primary-8: #73080E;
  --color-primitives-primary-9: #430508;

  --color-primitives-neutrals-0: #FFFFFF;
  --color-primitives-neutrals-1: #E6E6E6;
  --color-primitives-neutrals-2: #CCCCCC;
  --color-primitives-neutrals-3: #999999;
  --color-primitives-neutrals-4: #808080;
  --color-primitives-neutrals-5: #666666;
  --color-primitives-neutrals-6: #4D4D4D;
  --color-primitives-neutrals-7: #333333;
  --color-primitives-neutrals-8: #191919;
  --color-primitives-neutrals-9: #111111;
  --color-primitives-neutrals-10: #000000;

  --color-primitives-glass-fill: #0000001A;
  --color-primitives-glass-stroke: #FFFFFF1A;
  --color-primitives-glass-fill-dark: #00000070;
  --color-primitives-glass-color: #F15A2D66;


  --color-primary: var(--color-primitives-primary-4);
  --color-primary-dark: var(--color-primitives-primary-5);
  --color-primary-darker: var(--color-primitives-primary-6);
  --color-primary-on: var(--color-primitives-neutrals-0);

  --color-secondary: var(--color-primitives-primary-1);
  --color-secondary-dark: var(--color-primitives-primary-3);
  --color-secondary-darker: var(--color-primitives-primary-8);
  --color-secondary-on: var(--color-primitives-primary-0);


  --color-neutrals-black: var(--color-primitives-neutrals-10);
  --color-neutrals-darkest: var(--color-primitives-neutrals-9);
  --color-neutrals-darker: var(--color-primitives-neutrals-8);
  --color-neutrals-dark: var(--color-primitives-neutrals-7);
  --color-neutrals-light: var(--color-primitives-neutrals-3);
  --color-neutrals-lighter: var(--color-primitives-neutrals-2);
  --color-neutrals-lightest: var(--color-primitives-neutrals-1);
  --color-neutrals-white: var(--color-primitives-neutrals-0);

  --color-highlight: var(--color-primitives-primary-4);


  --color-mapped-text-content-primary: #FFFFFF;
  --color-mapped-text-content-secondary: #E6E6E6;
  --color-mapped-text-content-tertiary: #CCCCCC;
  --color-mapped-text-disabled: #999999;
  --color-mapped-text-on-disabled: #333333;
  --color-mapped-text-action-1: #F15A2D;
  --color-mapped-text-action-1-hover: #CF3C20;
  --color-mapped-text-on-action: #FFFFFF;
  --color-mapped-text-action-2: #FEEAD4;

  --color-mapped-surface: #FFFFFF;
  --color-mapped-surface-high: #111111;
  --color-mapped-surface-higher: #191919;
  --color-mapped-surface-disabled: #333333;
  --color-mapped-surface-action-1: #F15A2D;
  --color-mapped-surface-action-1-hover: #CF3C20;
  --color-mapped-surface-action-2: #FEEAD4;
  --color-mapped-surface-action-2-hover: #FDCFAB;
  --color-mapped-surface-highlight: #F15A2D;
  --color-mapped-surface-on-action: #FFFFFF;
  --color-mapped-surface-on-action-hover: #E6E6E6;

  --color-mapped-border-surface-high: #111111;
  --color-mapped-border-surface-higer: #191919;
  --color-mapped-border-action-1: #F15A2D;
  --color-mapped-border-action-1-hover: #AD2316;
  --color-mapped-border-action-2-hover: #FAAE80;


  /* External Fonts  */
  --font-family-1:'Neue Haas Grotesk Display Pro', sans-serif;
  --font-family-2:"Space Grotesk", sans-serif;
  --font-family-3: "Architects Daughter", cursive;

  /* spacing*/
  --space-1:16px;
  --space-2:24px;
  --space-3:48px;
  --space-4: 64px;
  --space-5: 128px;


  --spacing-16:16px;
  --spacing-32: 32px;

  --max-width: 78.5rem;
  --vertical-space: 8rem;
}

h1, h2, h3, h4, h5 , h6, p 
{
  margin: 0;
}

@media screen and (max-width: 1000px) {
  :root {
    --vertical-space: 8rem;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --vertical-space: 2rem;
  }
}