.video-player 
{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    isolation: isolate;
    width: 100%;
    max-width: 1512px;
    height: 706px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    z-index: 2;
    box-sizing: border-box;
    margin: 0 auto;
}
.video-player .video-section 
{
    width:100%;
    max-width: 1200px;
    aspect-ratio: 16/9;
    background: var(--color-primitives-glass-fill-dark);
    backdrop-filter: blur(16px);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    cursor:  none !important;
}
.video-player video 
{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.video-player .playBtn 
{
    position: absolute;
    cursor: pointer;
    z-index: 2;
}
.video-player .desktop 
{
    display: flex;
    opacity: 0;
    width: 112px;
    height: 112px;
    padding: var(--space-3, 48px);
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 8px;
    flex-wrap: wrap;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    border: 1px solid var(--color-primitives-glass-stroke);
    background: var( --color-primitives-glass-fill-dark);
    backdrop-filter: blur(16px);
    transition: 1000ms ease-in-out;
}
.video-player .video-section:hover
{
    .desktop
    {
        opacity: 1;
    }
}
.video-player img.playBtn.mobile 
{
    display: none;
}

@media (max-width:768px)
{
    .video-player 
    {
        padding: 0 1.5rem;
    }
    .video-player img.playBtn.mobile  
    {
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .video-player .video-section 
    {
        width: 100%;
        border-radius: 4px;
    }
}