@media screen and (min-width: 769px) {
    .pricing.pricing-container {
        padding: var(--vertical-space) 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: url("./assets/background.svg");
        background-size: cover;
        background-position: center;
    }
    .pricing-container .pricing-title {
        margin-bottom: 1rem;
        max-width: var(--max-width);
    }
    .pricing-cards-container .body-2 .title-2 {
        margin-bottom: 0;
    }
    .pricing-subtitle {
        margin-bottom: 4rem;
        max-width: var(--max-width);
    }
    .pricing-cards-container {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
        max-width: var(--max-width);
    }
    .card-one-container {
        display: flex;
        flex-direction: column;
        padding: 1.5rem 1.5rem 1rem 1.5rem;
        justify-content: space-between;
        border-radius: 0.5rem;
        border: 1px solid var(--color-primitives-glass-color);
        background: url("./assets/card-one-background.webp");
        background-size: cover;
        background-position: top;
        max-width: 100%;
        width: 100%;
    }
    .card-one-title {
        margin-bottom: 1rem;
    }
    .card-subtitle {
        margin-bottom: 1.5rem;
        color: var(--color-primitives-neutrals-1);
    }
    .stroke {
        margin-bottom: 1.5rem;
    }
    .pricing-card-feature-list {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 10.875rem;
        color: var(--color-primitives-neutrals-1);
    }
    .pricing-card-feature {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
    .pricing-card-two-container {
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
        border-radius: 0.5rem;
        border: 1px solid
            var(--color-primitives-glass-stroke, rgba(255, 255, 255, 0.1));
        /* glass-blur */
        backdrop-filter: blur(16px);
        max-width: 100%;
        width: 100%;
        transition: background 0.3s ease;
    }
    .essential-background {
        background: var(--color-primitives-glass-fill, rgba(0, 0, 0, 0.1));
    }
    .pro-background {
        background: url("./assets/card-one-background.webp");
        background-size: cover;
        background-position: top;
    }
    .pro-text {
        color: var(--color-primitives-neutrals-1);
    }
    .pricing-card-two-title {
        margin-bottom: 1.5rem;
    }
    .pricing-card-two-price {
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 0.5rem;
    }
    .pricing-card-two-price .title-2 {
        margin-bottom: 0;
    }
    .pricing-card-two-suggestion {
        margin-bottom: 1.5rem;
    }
    .pricing-card-two-line {
        margin-bottom: 1.5rem;
    }
    .pricing-card-two-feature-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 1.25rem;
    }
    .pricing-card-two-subfeatures {
        margin-top: 0.56rem;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        padding-left: 3.5rem;
        align-items: flex-start;
        margin-bottom: 0.19rem;
    }
    .pricing-card-info {
        text-transform: uppercase;
        margin-bottom: 1.25rem;
        font-family: "Space Grotesk";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px; /* 116.667% */
        letter-spacing: 0.6px;
        color: var(--color-primitives-neutrals-2);
    }
    .pricing-card-three-title-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 1rem;
        gap: 1rem;
    }
    .toggle-container {
        display: inline-block;
    }

    .toggle-input {
        display: none;
    }

    .toggle-label {
        display: flex;
        align-items: center;
        width: 9rem;
        height: 40px;
        background-color: #1c1c1e;
        border-radius: 20px;
        cursor: pointer;
        overflow: hidden;
        padding: 2px;
        box-sizing: border-box;
        min-height: 2rem;
        background: var(--color-primitives-neutrals-9);
    }

    .toggle-option {
        flex: 1;
        text-align: center;
        padding: 5px;
        color: white;
        font-family: Arial, sans-serif;
        font-size: 14px;
        transition: all 0.3s ease;
        border-radius: 625rem;
        padding: 0.5rem 1rem;
    }

    .toggle-option.active {
        background-color: var(--color-primitives-primary-4);
    }

    .toggle-option.inactive {
        background-color: transparent;
    }
    .pricing-card-three-disclaimer {
        margin-bottom: 0.5rem;
        color: var(--color-primitives-primary-4);
    }
    .pricing-card-three-feature-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 1rem;
    }
    .pricing-card-three-info {
        margin-bottom: 2.56rem;
        text-transform: uppercase;
        font-family: "Space Grotesk";
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 0.875rem; /* 116.667% */
        letter-spacing: 0.0375rem;
        text-transform: uppercase;
    }
    .pricing-card-button-wrapper {
        width: 100%;
        margin-top: auto;
    }
    .told-your-friends-flex-container {
        border-radius: 0.5rem;
        border: 1px solid
            var(--color-primitives-glass-stroke, rgba(255, 255, 255, 0.1));
        background: var(--color-primitives-glass-fill, rgba(0, 0, 0, 0.1));
        /* glass-blur */
        backdrop-filter: blur(16px);
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 1.5rem;
        max-width: 78.5rem;
    }
    .told-your-friends-text-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        max-width: var(--max-width);
    }
    .told-your-friends-subtitle {
        color: var(--color-primitives-neutrals-2);
    }
    .subtitle-highlighted-text {
        color: var(--color-primitives-primary-4);
    }
    .told-your-friends-button-wrapper {
        text-wrap: nowrap;
    }
}

@media screen and (max-width: 768px) {
    .pricing.pricing-container {
        max-width: 100%;
        padding: 3rem 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background: url("./assets/background.svg");
        background-size: cover;
        background-position: center;
    }

    .pricing-title {
        margin-bottom: 1rem;
        font-family: var(--font-family-2) !important;
    }

    .pricing-subtitle {
        margin-bottom: 2rem;
        text-align: center;
    }

    .pricing-cards-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .pricing-card-two-price-text {
        font-weight: 500 !important;
        margin-bottom: 0rem !important;
    }

    .pricing-card-two-subfeatures .body-2 {
        font-size: 14px !important;
    }

    .card-one-container {
        display: flex;
        flex-direction: column;
        padding: 1.5rem 1.5rem 0.875rem 1.5rem;
        border-radius: 0.25rem;
        border: 1px solid var(--color-primitives-glass-color);
        background: url("./assets/card-one-background.webp");
        background-size: cover;
        background-position: top;
    }
    .card-one-title {
        margin-bottom: 1rem;
    }
    .card-one-container p {
        color: var(--color-primitives-neutrals-1);
    }
    .card-subtitle {
        margin-bottom: 1.5rem;
        color: var(--color-primitives-neutrals-1);
    }

    .stroke {
        margin-bottom: 1.5rem;
    }

    .pricing-card-feature-list {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 8.245rem;
    }
    .pricing-card-feature {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
    .pricing-card-two-container {
        display: flex;
        flex-direction: column;
        border-radius: 0.5rem;
        padding: 1.5rem;
        border: 1px solid
            var(--color-primitives-glass-stroke, rgba(255, 255, 255, 0.1));
        backdrop-filter: blur(16px);
        background: rgba(0, 0, 0, 0.1);
    }
    .essential-background {
        background: var(--color-primitives-glass-fill, rgba(0, 0, 0, 0.1));
        /* glass-blur */
        backdrop-filter: blur(16px);
    }
    .pro-background {
        background: url("./assets/card-one-background.webp");
        background-size: cover;
        background-position: top;
    }
    .pro-text {
        color: var(--color-primitives-neutrals-1);
    }
    .pricing-card-two-title {
        margin-bottom: 1.5rem;
    }
    .pricing-card-two-price {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        margin-bottom: 0.5rem;
        color: var(--color-primitives-neutrals-2);
    }
    .pricing-card-two-price {
        color: var(--color-primitives-neutrals-0);
    }
    .pricing-card-two-suggestion {
        margin-bottom: 1rem;
        color: var(--color-primitives-neutrals-1);
    }
    .pricing-card-two-line {
        margin-bottom: 1rem;
    }
    .pricing-card-two-feature-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 1rem;
        color: var(--color-primitives-neutrals-1);
    }
    .pricing-card-two-subfeatures {
        display: flex;
        flex-direction: column;
        padding: 0;
        align-items: flex-start;
        padding: 0.56rem 0rem 0.81rem 3.5rem;
        gap: 0.75rem;
    }
    .pricing-card-info, .pricing-card-three-info  {
        margin-bottom: 1rem;
        text-transform: uppercase;
        color: var(--color-primitives-neutrals-2);
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        font-family: var(--font-family-2);
    }
    .pricing-card-three-title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
    }
    .toggle-container {
        display: inline-block;
    }

    .toggle-input {
        display: none;
    }

    .toggle-label {
        display: flex;
        align-items: center;
        width: 8.6875rem;
        height: 40px;
        background-color: #1c1c1e;
        border-radius: 20px;
        cursor: pointer;
        overflow: hidden;
        padding: 2px;
        box-sizing: border-box;
        min-height: 2rem;
        background: var(--color-primitives-neutrals-9);
    }

    .toggle-option {
        flex: 1;
        text-align: center;
        padding: 5px;
        color: white;
        font-family: Arial, sans-serif;
        font-size: 14px;
        transition: all 0.3s ease;
        border-radius: 625rem;
        padding: 0.5rem 1rem;
    }

    .toggle-option.active {
        background-color: var(--color-primitives-primary-4);
    }

    .toggle-option.inactive {
        background-color: transparent;
    }
    .pricing-card-three-disclaimer {
        color: var(--color-primitives-primary-4);
        margin-bottom: 0.5rem;
    }
    .pricing-card-three-feature-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 1rem;
        color: var(--color-primitives-neutrals-1);
    }
    .pricing-card-three-info {
        color: var(--color-primitives-neutrals-2);
        text-transform: uppercase;
        margin-bottom: 2.69rem;
    }
    .told-your-friends-flex-container {
        border-radius: 0.5rem;
        border: 1px solid
            var(--color-primitives-glass-stroke, rgba(255, 255, 255, 0.1));
        background: var(--color-primitives-glass-fill, rgba(0, 0, 0, 0.1));
        /* glass-blur */
        backdrop-filter: blur(16px);
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1.5rem;
    }
    .told-your-friends-text-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
    .told-your-friends-title {
        font-family: var(--font-family-2) !important;
        font-size: 28px !important;
        line-height: 28px !important;
        letter-spacing: -0.28px !important;
    }
    .told-your-friends-subtitle {
        color: var(--color-mapped-text-content-tertiary);
        font-size: 16px !important;
        line-height: 23px !important;
    }
    .subtitle-highlighted-text {
        color: var(--color-primitives-primary-4);
    }
}

@media (min-width: 769px) and (max-width: 952px) {
    .pricing-card-one-button-wrapper {
        margin-top: auto;
        justify-content: space-between;
    }
}
